import debug from 'debug';

import {isProductionBuild} from '../config';

if (!isProductionBuild) {
  // make it easy for the developer to know about the logger
  // and setup a default level if they don't have one already
  try {
    const currentLevel = localStorage.getItem('debug');
    if (currentLevel) {
      debug('paystar:info')(`current log level set to: ${currentLevel}`);
    } else {
      localStorage.setItem('debug', 'paystar:*');
    }
  } catch (error) {
    debug('paystar:info')('Failed to initialize debug level');
  }
}

type LogFunction = (message: string, ...optionalParams: any[]) => void;

type Logger = {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
};

const createLogger = (namespace, subject, level) => {
  const logger = debug(`${namespace}:${subject}:${level}`);
  return (message: string, ...optionalParams: any[]) => {
    logger(message, ...optionalParams);
  };
};

const DEFAULT_NAMESPACE = 'paystar';

export const logger: (namespace, config?) => Logger = (
  subject,
  config = {
    namespace: DEFAULT_NAMESPACE,
  }
) => {
  const namespace = config.namespace || DEFAULT_NAMESPACE;
  return {
    debug: createLogger(namespace, subject, 'debug'),
    info: createLogger(namespace, subject, 'info'),
    warn: createLogger(namespace, subject, 'warn'),
    error: createLogger(namespace, subject, 'error'),
  };
};

const developmentLogger = logger('development');

export {developmentLogger as debug};
