import React from 'react';
import {Form, StrictFormFieldProps} from 'semantic-ui-react';
import {Field, FieldRenderProps} from 'react-final-form';

import {SubmitError} from './submit-error';

type FieldProps<T> = T & {
  fieldName: string;
  fieldLabel?: string;
  width?: StrictFormFieldProps['width'];
};

type RenderComponentProps<T> = FieldRenderProps<T, HTMLElement>;

type RenderComponent<T> = {
  renderComponent: ({
    input,
    meta,
    ...rest
  }: RenderComponentProps<T>) => React.ReactNode;
};

export function FieldFactory<T>({renderComponent}: RenderComponent<T>) {
  return ({fieldName, width, fieldLabel, ...componentProps}: FieldProps<T>) => {
    return (
      <Field
        id={fieldName}
        name={fieldName}
        render={({input, meta, ...rest}) => {
          const fieldComponentProps = {input, meta, ...rest};
          return (
            <Form.Field width={width} error={!!meta.submitError}>
              {fieldLabel && <label htmlFor={input.name}>{fieldLabel}</label>}
              {renderComponent(fieldComponentProps)}
              <SubmitError name={input.name} />
            </Form.Field>
          );
        }}
        {...componentProps}
      />
    );
  };
}
