import * as firebase from 'firebase/app';
import 'firebase/database';
import {useListVals, useObjectVal} from 'react-firebase-hooks/database';

import axios from 'axios';
import {find, random, filter, keys} from 'lodash';
import cuid from 'cuid';
import moment from 'moment';

export type DemoContext = {
  orgName: string;
  businessUnitName: string;
  businessUnitImage: string;
  defaultPaymentMethodId?: string;
  feeAmount: number;
  account: {
    balance: number;
    accountNumber: string;
    accountDescription: string;
    dueDate: Date;
    name: string;
    emailAddress: string;
    phoneNumber: string;
    pdfLink: string;
  };
  autopaySettings: {
    enrolled: boolean;
    paymentMethodId: number;
  };
  billReminderSettings: {
    enrolled: boolean;
    emailEnabled: boolean;
    textEnabled: boolean;
  };
};

export type DemoPaymentMethod = {
  id: string;
  key?: string;
  cardholderName: string;
  token: string;
  expiration: string;
  postalCode: string;
  cvv: string;
  last4: string;
  type: 'Visa' | 'Mastercard' | 'Amex' | 'Unknown';
};

export type DemoPaymentBase = {
  transDate: number;
  isFutureDated: boolean;
  amount: number;
  cardUsed: string;
};

export type DemoPayment = DemoPaymentBase & {
  partialPayment: boolean;
  channel: 'IVR' | 'Customer Web' | 'Text to Pay';
  authCode: string;
  transId: string;
  key?: string;
};

const sleep = async ms => new Promise(r => void setTimeout(r, ms));

const firebaseConfig = {
  apiKey: 'AIzaSyD1XW7ATA92rxFO-RTIePXnKw0BBgFAfFU',
  authDomain: 'customer-demo-58c4a.firebaseapp.com',
  databaseURL: 'https://customer-demo-58c4a.firebaseio.com',
  projectId: 'customer-demo-58c4a',
  storageBucket: '',
  messagingSenderId: '713189360575',
  appId: '1:713189360575:web:ce82e9653ef8f1d4',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const database = firebase.database();

export const paths = {
  context: 'context',
  accounts: 'accounts',
  paymentMethods: 'payment-methods',
  payments: 'payments',
};

export const usePaymentMethod = paymentMethodId => {
  const [paymentMethods = [], loading, error] = useListVals<DemoPaymentMethod>(
    database.ref(paths.paymentMethods)
  );

  let paymentMethod = find(paymentMethods, {id: paymentMethodId});

  return {
    paymentMethod,
    loading,
    error,
  };
};

export const usePaymentMethods = () => {
  const [paymentMethods = [], loading, error] = useListVals<DemoPaymentMethod>(
    database.ref(paths.paymentMethods),
    {keyField: 'key'}
  );

  return {
    paymentMethods,
    loading,
    error,
  };
};

export const useContext = () => {
  const [context, loading, error] = useObjectVal<DemoContext>(
    database.ref(paths.context)
  );

  const paymentMethodId = context ? context.defaultPaymentMethodId : undefined;

  const paymentMethodState = usePaymentMethod(paymentMethodId);

  return {
    loading: loading || paymentMethodState.loading,
    error,
    context: context
      ? {
          ...context,
          defaultPaymentMethod: paymentMethodState.paymentMethod,
        }
      : undefined,
  };
};

export const usePayments = () => {
  const [payments = [], loading, error] = useListVals<DemoPayment>(
    database.ref(paths.payments).orderByChild('transDate'),
    {keyField: 'key'}
  );

  const scheduled = payments
    .filter(x => moment().isBefore(x.transDate))
    .sort((a, b) => (a.transDate > b.transDate ? 1 : -1));
  const complete = payments
    .filter(x => moment().isAfter(x.transDate))
    .sort((a, b) => (a.transDate > b.transDate ? -1 : 1));

  return {
    scheduled,
    complete,
    loading,
    error,
  };
};

export const makePayment = async (
  payment: DemoPaymentBase,
  context: DemoContext,
  paymentMethod: DemoPaymentMethod
) => {
  await sleep(500);

  const payload: DemoPayment = {
    ...payment,
    partialPayment: false,
    channel: 'Customer Web',
    transDate: payment.transDate,
    transId: random(40000000, 50000000).toString(),
    authCode: cuid.slug(),
  };

  await database.ref(paths.payments).push(payload);

  if (!payment.isFutureDated) {
    const url =
      'https://demo-paystar-functions.azurewebsites.net/api/SendTemplateEmail?code=Esz6KodvtJkFlvNifTOBkTiItterNKHeVNqswz9wMBMjKMJO4wekaQ==';
    axios.post(url, {
      ToEmail: context.account.emailAddress,
      TemplateId: 'd-6f7d5b77bafe48c381f1caae589d55f4',
      TemplateData: {
        transactionNumber: payload.transId,
        date: moment(new Date(payload.transDate)).format('MM/DD/YYYY'),
        paidTotal: payload.amount.toFixed(2),
        url: 'https://demo.paystar.co',
        businessUnitName: context.businessUnitName,
        businessUnitImage: context.businessUnitImage,
        feeAmount: context.feeAmount,
        cardType: paymentMethod.type,
        cardLast4: paymentMethod.last4,
        accountNumber: context.account.accountNumber,
        organizationName: context.orgName,
      },
    });
  }
};

export const deletePayment = async (key: string) => {
  await sleep(500);
  await database.ref(`${paths.payments}/${key}`).remove();
};

export const deletePaymentMethod = async (key: string) => {
  await sleep(500);
  await database.ref(`${paths.paymentMethods}/${key}`).remove();
};

export const setDefaultPaymentMethod = async defaultPaymentMethodId => {
  await database
    .ref(`${paths.context}/defaultPaymentMethodId`)
    .set(defaultPaymentMethodId);
};

export const resetDefaultPaymentMethod = async currentKey => {
  const ref = database.ref(paths.paymentMethods);
  const paymentMethodsSnapshot = await ref.once('value');
  const paymentMethods: Array<DemoPaymentMethod> = paymentMethodsSnapshot.val();

  const paymentMethodKeys = filter(keys(paymentMethods), x => x !== currentKey);
  const paymentMethodKey = paymentMethodKeys.length
    ? paymentMethodKeys[0]
    : undefined;

  if (paymentMethodKey) {
    const paymentMethod = paymentMethods[paymentMethodKey];

    await database
      .ref(`${paths.context}/defaultPaymentMethodId`)
      .set(paymentMethod.id);
  }
};

export const mergeAccount = async (account, newProps) => {
  await database.ref(`${paths.context}/account`).set({
    ...account,
    ...newProps,
  });
};

export const setAccountSetting = async (path, val) => {
  await database.ref(`${paths.context}/${path}`).set(val);
};
