import * as Sentry from '@sentry/browser';
import {last} from 'lodash';
import {StackFrame} from 'stacktrace-js';

import {isProductionBuild} from '../config';
import {logger} from './logger';

const log = logger('guard-util');

function guard(fn, defaultValue) {
  return (...args) => {
    try {
      return fn(...args);
    } catch (error) {
      return handleError(error, defaultValue);
    }
  };
}

function guardAsync(fn, defaultValue) {
  return (...args) =>
    fn(...args).then(x => x, error => handleError(error, defaultValue));
}

function handleError(error, defaultValue): typeof defaultValue {
  if (isProductionBuild) {
    Sentry.captureException(error);
  } else {
    handleDevelopmentError(error);
  }
  return defaultValue;
}

function parseErrorInfo(error: Error) {
  const errorStackParser = require('error-stack-parser');
  const stackInfo = last<StackFrame>(errorStackParser.parse(error));

  if (!stackInfo) {
    return {
      title: error.message,
      cacheKey: error.message || 'Unknown function name',
    };
  }

  return {
    title:
      stackInfo.functionName ||
      stackInfo.fileName ||
      stackInfo.source ||
      'Unknown function name',
    cacheKey: stackInfo.source || error.message || 'Unknown source',
  };
}

const developmentErrorNotificationCache = {};
function handleDevelopmentError(error: Error) {
  const notifications = require('simple-web-notification');
  const errorInfo = parseErrorInfo(error);

  if (!developmentErrorNotificationCache[errorInfo.cacheKey]) {
    log.info(`Guarded error caught (${errorInfo.title})`);
    log.error(error.message, error);

    notifications.showNotification('Guarded error caught', {
      body: errorInfo.title,
      icon: 'my-icon.ico',
    });

    developmentErrorNotificationCache[errorInfo.cacheKey] = true;
  }
}

export {guard, guardAsync};
