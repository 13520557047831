import React, {useState} from 'react';
import styled from '@emotion/styled';
import {cx} from 'emotion';
import {Image, Segment, Label, Button, Popup} from 'semantic-ui-react';
import {useAsyncCallback} from 'react-async-hook';

import visa from 'payment-icons/svg/single/visa.svg';
import mastercard from 'payment-icons/svg/single/mastercard.svg';
import amex from 'payment-icons/svg/single/amex.svg';
import card from 'payment-icons/svg/outline/default.svg';

import {useNotification} from '../hooks/use-notifications';
import {LiteralKeyedObject} from '../types';
import {
  deletePaymentMethod,
  setDefaultPaymentMethod,
  resetDefaultPaymentMethod,
} from '../apps/customer-portal/demo/firebase';

export type CreditCard = {
  id: string;
  key?: string;
  type: 'Visa' | 'Mastercard' | 'Amex' | 'Unknown';
  last4: string;
  cardholderName: string;
  expiration: string;
  isDefault?: boolean;
};

type CreditCardSource = {
  card: CreditCard;
  showEditActions: boolean;
  onSelect?: (card: CreditCard) => void;
  onUpdateDefault?: (card: CreditCard) => void;
};

type CardTypeMap = LiteralKeyedObject<CreditCard['type'], any>;

const StyledSegment = styled(Segment)`
  &.ui.segment {
    width: 100%;
    margin: 0 0 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    padding: 1rem 1rem;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
    align-items: center;

    img.card-type {
      width: 50px;
      height: auto;
      font-size: 0.78571429rem;
    }

    .selection-icon {
      width: 40px;
      color: #185884;

      svg {
        display: none;
      }
    }

    &:hover {
      &.selectable {
        box-shadow: 0px 1px 3px 0px #a1a7ab, 0px 0px 0px 1px #a1a7ab;
        cursor: pointer;
      }

      .selection-icon svg {
        display: initial;
        color: #ddd;
      }

      .credit-card-buttons {
        display: initial;
        flex: 2;
      }
    }

    &.confirming {
      .credit-card-buttons {
        display: initial;
        flex: 2;
      }
    }

    &.selected {
      box-shadow: 0px 1px 3px 0px #185884, 0px 0px 0px 1px #185884;
      background: #f7f7f7;

      .selection-icon svg {
        color: #185884;
        display: initial;
      }
    }

    .ui.radio {
      margin-right: 10px;
    }

    .credit-card-buttons {
      display: none;
      text-align: right;

      .button {
        margin-left: 5px;
      }
    }

    .credit-card-info {
      text-align: right;
      flex: 1;
    }
    .card-number {
      font-weight: bold;
      font-size: 1.28571429rem;
      margin-top: -0.21425rem;
      line-height: 1.28571429rem;
    }
    .card-name {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.4);
    }
    .card-expiration {
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const cardTypeMap: CardTypeMap = {
  Visa: visa,
  Mastercard: mastercard,
  Amex: amex,
  Unknown: card,
};

export const CreditCardSource = (props: CreditCardSource) => {
  const {card, showEditActions, onSelect} = props;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [defaultConfirmOpen, setDefaultConfirmOpen] = useState(false);
  const deletePaymentMethodAsync = useAsyncCallback(async key => {
    await deletePaymentMethod(key);
  });
  const resetDefaultPaymentMethodAsync = useAsyncCallback(async key => {
    await resetDefaultPaymentMethod(key);
  });
  const notifications = useNotification();

  return (
    <StyledSegment
      as={showEditActions ? undefined : 'button'}
      type={showEditActions ? undefined : 'button'}
      className={cx('credit-card-source', {
        selectable: !!onSelect,
        confirming: deleteConfirmOpen || defaultConfirmOpen,
      })}
      onClick={() => {
        if (props.onSelect) {
          props.onSelect(card);
        }
      }}
    >
      <div>
        <Image src={cardTypeMap[card.type]} className="card-type" />
        {card.isDefault && (
          <Label size="mini" color="blue">
            Default
          </Label>
        )}
      </div>
      {showEditActions && (
        <div className="credit-card-buttons">
          {!card.isDefault && (
            <Popup
              on="click"
              position="left center"
              trigger={
                <Button size="mini" basic type="button">
                  Make Default
                </Button>
              }
              open={defaultConfirmOpen}
              onOpen={() => setDefaultConfirmOpen(true)}
              onClose={() => setDefaultConfirmOpen(false)}
              content={
                <Button
                  type="button"
                  onClick={async () => {
                    await setDefaultPaymentMethod(card.id);
                    notifications.success('Default payment method updated');
                    setDefaultConfirmOpen(false);
                  }}
                >
                  Confirm?
                </Button>
              }
            />
          )}
          <Popup
            on="click"
            position="left center"
            trigger={
              <Button size="mini" basic type="button">
                Remove
              </Button>
            }
            onOpen={() => setDeleteConfirmOpen(true)}
            onClose={() => setDeleteConfirmOpen(false)}
            content={
              <Button
                type="button"
                negative
                loading={deletePaymentMethodAsync.loading}
                onClick={async () => {
                  if (card.isDefault) {
                    await resetDefaultPaymentMethodAsync.execute(card.key);
                  }
                  await deletePaymentMethodAsync.execute(card.key);
                  notifications.success('Payment method removed');
                }}
              >
                Confirm?
              </Button>
            }
          />
        </div>
      )}
      <div className="credit-card-info">
        <div className="card-number">{` **** **** **** ${card.last4}`}</div>
        <div className="card-name">{card.cardholderName}</div>
        <div className="card-expiration">{card.expiration}</div>
      </div>
    </StyledSegment>
  );
};

CreditCardSource.defaultProps = {
  showEditActions: false,
};
