import React from 'react';
import {useField} from 'react-final-form';
import 'cleave.js/dist/addons/cleave-phone.us';

export const SubmitError = ({name}) => {
  const {
    meta: {submitError},
  } = useField(name, {
    subscription: {touched: true, submitError: true},
  });

  return submitError ? (
    <span className="sui-error-message">{submitError}</span>
  ) : null;
};
