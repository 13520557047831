import React, {useEffect, useState, useCallback} from 'react';
import qs from 'qs';
import {pick} from 'lodash';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import {PropertiesHyphen} from '../../types/index';
import {CardConnectTokenizerResponse} from './types';
import {usePostMessageHandler} from '../../hooks/use-post-message-handler';
import {guard, guardAsync} from '../../utils/guard';
import {copyElementStyles} from '../../styles/copy-element-styles';
import {analtyics} from '../../analytics';
import {logger} from '../../utils/logger';

type CardInfo = {
  type: string;
};

type CardConnectTokenizer = {
  target: React.MutableRefObject<HTMLInputElement>;
  onCapture: (response: CardConnectTokenizerResponse) => void;
  onCardInfo?: (cardInfo: CardInfo) => void;
  onLoad?: () => void;
  onError?: (error) => void;
};

const log = logger('card-connect-tokenizer');
const baseUrl = process.env.REACT_APP_CARDCONNECT_BASE_URL;
const defaultIframeTokenizerProps = {
  invalidinputevent: true,
  tokenizewheninactive: true,
  enhancedresponse: true,
  formatinput: true,
};

export const CardConnectTokenizer = (props: CardConnectTokenizer) => {
  const {target, onCapture, onCardInfo, onLoad, onError} = props;

  const [inputStyles, setInputStyles] = useState();
  const [inputHeight, setInputHeight] = useState();

  usePostMessageHandler({
    filter: useCallback((event: MessageEvent) => {
      return event.origin === baseUrl;
    }, []),
    onMessage: useCallback(
      (event: MessageEvent) => {
        const response = parseCardConnectMessage(event.data);
        onCapture(response);

        log.info('card capture message', {
          entry: response.entry,
          errorCode: response.errorCode,
        });

        if (response.isValid && onCardInfo) {
          (async () => {
            const cardInfo = await fetchBinInfo(response.token);
            log.info(`card type fetched: ${cardInfo.type}`);
            onCardInfo(cardInfo);
          })();
        }
      },
      [onCapture, onCardInfo]
    ),
  });

  useEffect(() => {
    if (target.current) {
      const clonedStyles = copyElementStyles(target.current, {
        styleWhitelist: acceptedCssProperties,
        explicitValues: {
          width: '100%',
          display: 'inline-block',
        },
      });
      setInputStyles(clonedStyles);
      setInputHeight(
        parseInt(window.getComputedStyle(target.current).height as string, 10) +
          1
      );

      analtyics.time_event('loaded:card-connect-tokenizer');
    }
  }, [target]);

  const onFrameLoaded = () => {
    analtyics.track('loaded:card-connect-tokenizer');

    if (onLoad) {
      onLoad();
    }
  };

  const onFrameError = error => {
    const exception =
      error || new Error('Failed to load card-connect-tokenizer iframe');

    log.error('Failed to load card-connect-tokenizer iframe', exception);
    Sentry.captureException(exception);

    if (onError) {
      onError(error);
    }
  };

  if (inputStyles && inputStyles.text) {
    const bodyStyles = 'margin: 0;';
    const tokenizerProps = {
      ...defaultIframeTokenizerProps,
      css: `body{${bodyStyles}}input{${inputStyles.text}}`,
    };
    const url = `${baseUrl}/itoke/ajax-tokenizer.html?${qs.stringify(
      tokenizerProps
    )}`;

    return (
      <iframe
        id="cardconnect-token-frame"
        name="cardconnect-token-frame"
        title="card connect credit card input"
        src={url}
        frameBorder="0"
        scrolling="no"
        width="100%"
        height={inputHeight}
        onLoad={onFrameLoaded}
        onError={onFrameError}
      />
    );
  }

  return null;
};

const parse = guard(input => JSON.parse(input), {});

const documentedCardConnectResponseKeys = [
  'entry',
  'errorCode',
  'errorMessage',
  'token',
];

const binCache = {};
const fetchBinInfo = guardAsync(
  async (cardConnectToken: string) => {
    if (binCache[cardConnectToken]) {
      return binCache[cardConnectToken];
    }

    const url = `http://localhost:1234/card-connect/bin?token=${cardConnectToken}`;
    const {data} = await axios.get(url);

    binCache[cardConnectToken] = data;
    return data as CardInfo;
  },
  {type: 'unknown'}
) as (cardConnectToken: string) => CardInfo;

const parseCardConnectMessage = (messageJsonString: string) => {
  const data = parse(messageJsonString);
  return {
    ...(pick(
      data,
      documentedCardConnectResponseKeys
    ) as CardConnectTokenizerResponse),
    isValid: !!data.token && parseInt(data.errorCode, 10) === 0,
  } as CardConnectTokenizerResponse;
};

const acceptedCssProperties: PropertiesHyphen[] = [
  '-moz-appearance',
  '-moz-border-radius',
  '-moz-border-radius-bottomleft',
  '-moz-border-radius-bottomright',
  '-moz-border-radius-topleft',
  '-moz-border-radius-topright',
  '-moz-box-shadow',
  '-moz-outline',
  '-moz-outline-color',
  '-moz-outline-style',
  '-moz-outline-width',
  '-o-text-overflow',
  '-webkit-appearance',
  '-webkit-border-bottom-left-radius',
  '-webkit-border-bottom-right-radius',
  '-webkit-border-radius',
  '-webkit-border-top-left-radius',
  '-webkit-border-top-right-radius',
  '-webkit-box-shadow',
  'appearance',
  'azimuth',
  'background',
  'background-attachment',
  'background-color',
  'background-image',
  'background-position',
  'background-repeat',
  'border',
  'border-bottom',
  'border-bottom-color',
  'border-bottom-left-radius',
  'border-bottom-right-radius',
  'border-bottom-style',
  'border-bottom-width',
  'border-collapse',
  'border-color',
  'border-left',
  'border-left-color',
  'border-left-style',
  'border-left-width',
  'border-radius',
  'border-right',
  'border-right-color',
  'border-right-style',
  'border-right-width',
  'border-spacing',
  'border-style',
  'border-top',
  'border-top-color',
  'border-top-left-radius',
  'border-top-right-radius',
  'border-top-style',
  'border-top-width',
  'border-width',
  'box-shadow',
  'box-sizing',
  'caption-side',
  'color',
  'cue',
  'cue-after',
  'cue-before',
  'direction',
  'elevation',
  'empty-cells',
  'font',
  'font-family',
  'font-size',
  'font-stretch',
  'font-style',
  'font-variant',
  'font-weight',
  'height',
  'letter-spacing',
  'line-height',
  'list-style',
  'list-style-image',
  'list-style-position',
  'list-style-type',
  'margin',
  'margin-bottom',
  'margin-left',
  'margin-right',
  'margin-top',
  'max-height',
  'max-width',
  'min-height',
  'min-width',
  'outline',
  'outline-color',
  'outline-style',
  'outline-width',
  'padding',
  'padding-bottom',
  'padding-left',
  'padding-right',
  'padding-top',
  'pause',
  'pause-after',
  'pause-before',
  'pitch',
  'pitch-range',
  'quotes',
  'richness',
  'speak',
  'speak-header',
  'speak-numeral',
  'speak-punctuation',
  'speech-rate',
  'stress',
  'table-layout',
  'text-align',
  'text-decoration',
  'text-indent',
  'text-overflow',
  'text-shadow',
  'text-transform',
  'unicode-bidi',
  'vertical-align',
  'voice-family',
  'volume',
  'white-space',
  'width',
  'word-spacing',
  'word-wrap',
];
