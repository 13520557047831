import React from 'react';
import {Segment, Header, Grid, Statistic} from 'semantic-ui-react';
import {Link} from '@reach/router';
import {DateFormat} from '../../../component-lib/date';
import Currency from '../../../component-lib/currency';

type AccountCard = {
  href?: string;
  account: {
    number: string;
    description?: string;
    businessUnitName: string;
    customerName: string;
    balance: number;
    dueDate: Date | string;
    pdfLink: string;
  };
};

export const AccountCard = (props: AccountCard) => {
  const {account} = props;
  const linkProps = props.href
    ? {
        as: Link,
        to: props.href,
      }
    : {};

  return (
    <Segment className="account-card" {...linkProps}>
      <Grid verticalAlign="top">
        <Grid.Row>
          <Grid.Column width="10">
            <Header size="large">
              {account.businessUnitName}
              {account.description && (
                <Header.Subheader>{account.description}</Header.Subheader>
              )}
              <Header.Subheader>Account #: {account.number}</Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width="6" textAlign="right">
            <Statistic size="small">
              <Statistic.Value style={{color: 'green'}}>
                <Currency amount={account.balance} />
              </Statistic.Value>
              <Statistic.Label>
                {'Due: '}
                <DateFormat date={account.dueDate} />
                <div>
                  <a
                    href={account.pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Bill
                  </a>
                </div>
              </Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
