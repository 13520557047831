import styled from '@emotion/styled/macro';
import {CreateStyled} from '@emotion/styled';

export type Theme = typeof defaultTheme;

const colors = {
  red1: '#B31A34',
  red2: '#DA1F3F',
  red3: '#E04761',
  red4: '#AA0000',
  blue1: '#0A2233',
  blue2: '#0D3047',
  blue3: '#144A6F',
  blue4: '#3E6A89',
  blue5: '#94ACBD',
  white1: '#FFFFFF',
  white2: '#F5F5F5',
  grey1: '#979C9E',
  grey2: '#CBCFD1',
  black: '#1B1C1D',
  white: '#fff',
  transparent: 'rgba(0,0,0,0)',
};

export const payStarColors = {
  ...colors,
  primary: {
    red: colors.red2,
    blue: colors.blue3,
    grey: '#B8BEC0',
  },
  basicBoxShadow: 'rgba(34, 36, 38, 0.15)',
  textLabel: '#444',
  transparentHover: '#efefef',
  warning: '#712928',
  warningBackground: '#fff6f6',
  warningBoxShadow: '#9F3A38',
};

export const defaultTheme = {
  logoSrc: '',
  primaryColor: payStarColors.primary.blue,
  hostedFormBackgroundColor: '#FFF',
  toastOffset: '0px',
};

export default styled as CreateStyled<Theme>;
