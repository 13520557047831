import React from 'react';
import moment from 'moment';

type DateProps = {
  date: number | Date | string;
  format?: string;
};

export const DateFormat = React.memo<DateProps>(
  ({date, format = 'MM/DD/YYYY'}) => (
    <React.Fragment>{moment(date).format(format)}</React.Fragment>
  )
);
