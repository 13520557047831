import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

import {isProductionBuild} from '../config';
import {logger} from '../utils/logger';

type Analytics = {
  track: Mixpanel['track'];
  time_event: Mixpanel['time_event'];
  page_view: (url: string) => void;
};

const log = logger('analytics');

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const initializeMixpanel = isProductionBuild && mixpanelToken;
const initializeGoogleAnalytics = isProductionBuild && !!googleAnalyticsId;

let analtyics: Analytics;

analtyics = {
  track: mockInDevelopment('track', mixpanel),
  time_event: mockInDevelopment('time_event', mixpanel),
  page_view: mockInDevelopment('pageview', ReactGA),
};

if (initializeMixpanel) {
  mixpanel.init(mixpanelToken);
}

if (initializeGoogleAnalytics) {
  ReactGA.initialize(googleAnalyticsId as string);
}

function mockInDevelopment(method, target) {
  return isProductionBuild
    ? (...args) => target[method](...args)
    : (...args) => log.info(`analytics.${method}`, ...args);
}

export {analtyics};
