import {useToasts} from 'react-toast-notifications';

type MessageTypes = 'success' | 'error' | 'info' | 'warning';

type Options = {
  autoDismiss: boolean; //	Default: false
  autoDismissTimeout: number;
  onDismiss: (id: string) => void;
  pauseOnHover: boolean;
  onShow: (id: string) => void;
};

const defaultOptions = {
  autoDismiss: true,
  autoDismissTimeout: 3000,
  pauseOnHover: true,
};

export function useNotification() {
  const {addToast} = useToasts();

  const createMessageType = (type: MessageTypes) => (
    message: string,
    options?: Options
  ) => {
    addToast(message, {
      appearance: type,
      ...defaultOptions,
      ...(options || {}),
    });
  };

  return {
    success: createMessageType('success'),
    error: createMessageType('error'),
    info: createMessageType('info'),
    warning: createMessageType('warning'),
  };
}
