import React from 'react';
import {Input, Dropdown} from 'semantic-ui-react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

export const InputFieldAdapter = ({input, meta, ...rest}) => {
  return (
    <Input
      {...input}
      {...rest}
      onChange={(event, {value}) => input.onChange(value)}
    />
  );
};

export const MaskedInputFieldAdapter = ({input, meta, ...rest}) => (
  <Cleave {...input} {...rest} />
);

export const DropdownFieldAdapter = ({input, meta, ...rest}) => (
  <Dropdown
    {...input}
    {...rest}
    onChange={(event, {value}) => input.onChange(value)}
  />
);
