import {values} from 'lodash';

import {PropertiesHyphen} from '../types/index';
import {convertToLookup} from '../utils/convert-to-lookup';

type ClonedStyles = {
  object: Partial<{[K in PropertiesHyphen]: any}>;
  text: string;
};

type CloneStylesConfig = {
  styleWhitelist?: PropertiesHyphen[];
  explicitValues?: Partial<{[K in PropertiesHyphen]: any}>;
};

export const copyElementStyles = (
  target: HTMLElement,
  config: CloneStylesConfig = {}
): ClonedStyles => {
  const {styleWhitelist, explicitValues = {}} = config;
  const targetStyles: CSSStyleDeclaration = window.getComputedStyle(target);

  const filterMap = styleWhitelist
    ? convertToLookup(styleWhitelist)
    : undefined;

  return values(targetStyles).reduce(
    (styles, propertyName) => {
      const shouldCopyStyle = !filterMap || !!filterMap[propertyName];

      if (shouldCopyStyle) {
        const computedValue = targetStyles.getPropertyValue(propertyName);
        const value = explicitValues[propertyName] || computedValue;
        styles.object[propertyName] = value;
        styles.text += `${propertyName}:${value};`;
      }

      return styles;
    },
    {
      object: {},
      text: '',
    }
  );
};
