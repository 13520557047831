import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'react-hot-loader';
import 'typeface-roboto';
import 'semantic-ui-less/semantic.less';
import '@reach/skip-nav/styles.css';

import * as Sentry from '@sentry/browser';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {ToastProvider} from 'react-toast-notifications';

import {isProductionBuild} from './config';
import {ThemeProvider} from './styles/theme-provider';
import CustomerApp from './apps/customer-portal/authenticated-app';
import styled from './styles/styled';

const sentryKey = process.env.REACT_APP_SENTRY_PUBLIC_KEY;
const sentryProjectId = process.env.REACT_APP_SENTRY_PUBLIC_PROJECT_ID;

const shouldInitializeSentry =
  isProductionBuild && sentryKey && sentryProjectId;

if (shouldInitializeSentry) {
  const regexMixpanel = /mixpanel/gi;
  const excludedHttpBreadcrumbs = [regexMixpanel];

  Sentry.init({
    dsn: `https://${sentryKey}@sentry.io/${sentryProjectId}`,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        const {url} = breadcrumb.data;
        if (excludedHttpBreadcrumbs.some(regex => regex.test(url))) {
          return null;
        }
      }
      return breadcrumb;
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // https://stackoverflow.com/a/50387233 All good man.
  });
}

const ToastContainer = styled.div`
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
  position: fixed;
  top: ${props => props.theme.toastOffset};
  right: 8px;
  z-index: 100;
`;

ReactDOM.render(
  <Suspense fallback={null}>
    <ThemeProvider>
      <ToastProvider components={{ToastContainer}}>
        <CustomerApp />
      </ToastProvider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById('root')
);
