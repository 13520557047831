import React, {useRef, useState} from 'react';
import css from '@emotion/css/macro';
import {Form, Image, Button, Dimmer} from 'semantic-ui-react';
import imageDefaultCard from 'payment-icons/svg/flat/default.svg';
import imageVisa from 'payment-icons/svg/flat/visa.svg';
import imageMastercard from 'payment-icons/svg/flat/mastercard.svg';
import imageAmex from 'payment-icons/svg/flat/amex.svg';
import imageDiscover from 'payment-icons/svg/flat/discover.svg';
import {Form as FinalForm, Field} from 'react-final-form';

import {CardConnectFormValues, CardConnectTokenizerResponse} from './types';
import {CardConnectTokenizer} from './tokenizer';
import {FieldInput} from '../../forms/fields';
import {SubmitError} from '../../forms/submit-error';
import imageCvv from '../../assets/icon-cvv.svg';

type CardConnectCreditCardForm = {
  onSubmit: (
    values: CardConnectFormValues
  ) => void | object | Promise<object> | undefined;
  onCancel?: () => void;
};

const styles = css`
  .input-with-image {
    position: relative;

    iframe {
      display: block;
    }
  }

  .card-preview {
    position: absolute;
    right: 6px;
    top: 50%;
    height: 28px;
    margin-top: -14px;
  }
`;

const cardImages = {
  visa: imageVisa,
  mastercard: imageMastercard,
  amex: imageAmex,
  discover: imageDiscover,
};

export const CardConnectCreditCardForm = (props: CardConnectCreditCardForm) => {
  const [loading, setLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState({
    type: 'unknown',
  });

  const [cardTokenState, setCardTokenState] = useState<
    CardConnectTokenizerResponse
  >();

  const targetInputRef = useRef<HTMLInputElement>(null);

  return (
    <Dimmer.Dimmable as="div" dimmed={loading}>
      <Dimmer active={loading} inverted />
      <div css={styles}>
        <FinalForm
          onSubmit={(values: CardConnectFormValues) => {
            return props.onSubmit({
              ...values,
              token: cardTokenState ? cardTokenState.token : '',
            });
          }}
          render={({handleSubmit, submitting, submitErrors}) => (
            <Form
              onSubmit={handleSubmit}
              loading={submitting}
              error={!!submitErrors}
            >
              <Form.Group>
                <Field
                  id="cardholderName"
                  name="cardholderName"
                  render={({input, meta}) => {
                    return (
                      <Form.Field width="16" error={!!meta.submitError}>
                        <label htmlFor={input.name}>Cardholder Name</label>
                        <div className="input-with-image">
                          <input {...input} ref={targetInputRef} />
                        </div>
                        <SubmitError name={input.name} />
                      </Form.Field>
                    );
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Field
                  width="16"
                  error={submitErrors && !!submitErrors.cardToken}
                >
                  <label>Card Number</label>
                  <div className="input-with-image">
                    <CardConnectTokenizer
                      target={
                        targetInputRef as React.MutableRefObject<
                          HTMLInputElement
                        >
                      }
                      onLoad={() => {
                        setLoading(false);
                      }}
                      onCapture={response => {
                        setCardTokenState(response);
                      }}
                      onCardInfo={cardInfo => {
                        setCardInfo(cardInfo);
                      }}
                    />
                    <Image
                      src={getCardImage(cardInfo.type)}
                      className="card-preview"
                    />
                  </div>
                  <SubmitError name="cardToken" />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <FieldInput
                  fieldName="expiration"
                  fieldLabel="Expiration"
                  width="8"
                  placeholder="MM/YY"
                  format={formatExpirationDate}
                />
                <Field
                  id="cvv"
                  name="cvv"
                  render={({input, meta, ...rest}) => {
                    return (
                      <Form.Field width="8" error={!!meta.submitError}>
                        <label htmlFor={input.name}>CVV</label>
                        <div className="input-with-image">
                          <input {...input} />
                          <Image src={imageCvv} className="card-preview" />
                        </div>

                        <SubmitError name={input.name} />
                      </Form.Field>
                    );
                  }}
                />
              </Form.Group>

              <Form.Group>
                <FieldInput
                  fieldName="postalCode"
                  fieldLabel="Postal Code"
                  width="8"
                />
              </Form.Group>

              <div className="form-actions">
                <Button type="submit" primary>
                  Save Card
                </Button>
                <Button
                  type="button"
                  className="clear"
                  onClick={() => {
                    if (props.onCancel !== undefined) {
                      props.onCancel();
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Dimmer.Dimmable>
  );
};

const getCardImage = type => cardImages[type] || imageDefaultCard;

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
