import React, {useState} from 'react';
import css from '@emotion/css/macro';
import {Message, Button, Form} from 'semantic-ui-react';

import {
  useContext,
  usePaymentMethods,
  setAccountSetting,
} from '../demo/firebase';
import {SavedCards} from '../routes/manage-wallet';
import {
  CreditCardSource,
  CreditCard,
} from '../../../component-lib/credit-card-source';
import {ThemeContainer} from '../../../styles/theme-provider';
import {AsyncStateContainer} from '../../../component-lib/async-state-container';
import {TermsOfUse} from '../demo/terms-of-use';

const styles = css`
  .ui.form {
    .field > label {
      font-weight: bold !important;
    }
  }
`;

type AutoPayEnrollmentProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const AutoPayEnrollment = (props: AutoPayEnrollmentProps) => {
  const asyncState = useContext();
  const {paymentMethods} = usePaymentMethods();
  const [showCardSelector, setShowCardSelector] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<
    undefined | {card: CreditCard}
  >(undefined);

  const {onSubmit, onCancel} = props;
  const {context} = asyncState;

  if (
    !paymentMethod &&
    !showCardSelector &&
    context &&
    context.defaultPaymentMethod
  ) {
    const card: CreditCard = {
      ...context.defaultPaymentMethod,
    };
    setPaymentMethod({card});
  }

  return (
    <AsyncStateContainer {...asyncState}>
      {context && (
        <div css={styles}>
          <ThemeContainer>
            <Form
              onSubmit={async e => {
                await setAccountSetting(
                  'autopaySettings/enrolled',
                  !context.autopaySettings.enrolled
                );
                await setAccountSetting(
                  'autopaySettings/paymentMethodId',
                  paymentMethod!.card.id
                );
                onSubmit();
              }}
            >
              <Message info>
                <Message.Header>About Auto Pay</Message.Header>
                <Message.Content>
                  <p style={{marginTop: '5px'}}>
                    Use the form below to sign up for convenient automatic
                    payments. Please note:
                  </p>
                  <ul style={{margin: 0}}>
                    <li>
                      Every month, the full balance of your latest bill will
                      automatically be charged to the card selected on its due
                      date.
                    </li>
                    <li>
                      Auto Pay can be cancelled at any time, up to 24 hours
                      before your next payment.
                    </li>
                  </ul>
                </Message.Content>
              </Message>

              {paymentMethods && (
                <Form.Field>
                  <label>Select Payment Method</label>
                  <p>
                    {showCardSelector && paymentMethods.length > 1
                      ? 'Choose a payment method to use for Auto Pay'
                      : 'Click below to change the selected payment method'}
                  </p>
                  {!showCardSelector && paymentMethod && (
                    <CreditCardSource
                      card={paymentMethod!.card}
                      onSelect={() => {
                        if (paymentMethods.length > 1) {
                          setPaymentMethod(undefined);
                          setShowCardSelector(true);
                        }
                      }}
                    />
                  )}
                  {showCardSelector && (
                    <SavedCards
                      onSelect={paymentMethod => {
                        setPaymentMethod({card: paymentMethod});
                        setShowCardSelector(false);
                      }}
                    />
                  )}
                </Form.Field>
              )}

              <TermsOfUse
                processText={'recurring payments in the future'}
                feeAmount={context.feeAmount}
                onToggle={accepted => {
                  setTermsAccepted(accepted);
                }}
              />

              <div className="form-actions" style={{marginTop: '10px'}}>
                <Button
                  primary
                  type="submit"
                  disabled={!termsAccepted || !paymentMethod}
                >
                  Submit Enrollment
                </Button>
                <Button type="button" className="clear" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </Form>
          </ThemeContainer>
        </div>
      )}
    </AsyncStateContainer>
  );
};
