import React, {useState} from 'react';
import css from '@emotion/css/macro';
import {
  Container,
  Segment,
  Header,
  Table,
  Button,
  Popup,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import {RouteComponentProps} from '@reach/router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck,
  faPhoneSquare,
  faCommentAltLines,
  faDesktop,
  faReceipt,
} from '@fortawesome/pro-regular-svg-icons';
import {upperFirst} from 'lodash';
import axios from 'axios';
import moment from 'moment';

import {LiteralKeyedObject} from '../../../types';
import {AsyncStateContainer} from '../../../component-lib/async-state-container';
import Currency from '../../../component-lib/currency';
import {DateFormat} from '../../../component-lib/date';
import {
  DemoPayment,
  useContext,
  usePaymentMethod,
  setAccountSetting,
  usePayments,
  deletePayment,
  DemoContext,
} from '../demo/firebase';
import {useAsyncCallback} from 'react-async-hook';
import {useNotification} from '../../../hooks/use-notifications';
import {AutoPayEnrollment} from '../demo/autopay-enrollment';

type paymentChannelIconMap = LiteralKeyedObject<DemoPayment['channel'], any>;

const styles = css`
  margin-bottom: 20;

  .settings {
    list-style-type: none;
    margin: 1em 0em;
    padding: 0em 0em;
    font-size: 1em;

    &:last-child {
      margin-bottom: 0em;
      padding-bottom: 0em;
    }
  }

  .setting {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.21428571em 0em;
    line-height: 1.14285714em;
    border-top: 1px solid rgba(34, 36, 38, 0.15);

    svg {
      margin-right: 10px;
    }

    &:first-of-type {
      padding-top: 0em;
      border-top: none;
    }

    &:not(:first-of-type) {
      padding-top: 0.42857143em;
    }

    &:not(:last-child) {
      padding-bottom: 0.42857143em;
    }
  }

  .setting-content {
    flex: 1;
  }

  .radio-options {
    flex: 1;

    .ui.checkbox {
      margin-left: 15px;
    }
  }
`;

const paymentChannelIcons: paymentChannelIconMap = {
  IVR: faPhoneSquare,
  'Customer Web': faDesktop,
  'Text to Pay': faCommentAltLines,
};

export const AccountDetails = (props: RouteComponentProps) => {
  const {context, loading, error} = useContext();
  const payments = usePayments();
  const [showAutoPayModal, setShowAutoPayModal] = useState(false);
  const asyncState = {loading, error};
  const deletePaymentAsync = useAsyncCallback(async key => {
    await deletePayment(key);
  });
  const notifications = useNotification();

  const autoPaySourceId = context
    ? context.autopaySettings.paymentMethodId
    : undefined;
  const autoPaySource = usePaymentMethod(autoPaySourceId).paymentMethod;

  return (
    <AsyncStateContainer {...asyncState}>
      {context && (
        <Container text css={styles}>
          <Segment>
            <Header>Account Settings</Header>
            <div className="settings">
              <div className="setting">
                <FontAwesomeIcon
                  icon={context.autopaySettings.enrolled ? faCheck : faTimes}
                  color={context.autopaySettings.enrolled ? 'green' : undefined}
                />
                <div className="setting-content">
                  {!context.autopaySettings.enrolled && (
                    <label>Not enrolled in auto pay</label>
                  )}
                  {context.autopaySettings.enrolled && (
                    <label>
                      Enrolled in auto pay
                      {autoPaySource &&
                        ` - ${upperFirst(autoPaySource.type)} ****${
                          autoPaySource.last4
                        }`}
                    </label>
                  )}
                </div>
                <div className="setting-action">
                  {context.autopaySettings.enrolled && (
                    <Popup
                      on="click"
                      position="left center"
                      trigger={
                        <Button className="clear" type="button">
                          Cancel
                        </Button>
                      }
                      content={
                        <Button
                          type="button"
                          negative
                          onClick={async () => {
                            await setAccountSetting(
                              'autopaySettings/enrolled',
                              false
                            );
                            notifications.success(
                              'Auto pay has been cancelled'
                            );
                          }}
                        >
                          Confirm?
                        </Button>
                      }
                    />
                  )}
                  {!context.autopaySettings.enrolled && (
                    <Button
                      type="button"
                      className="clear"
                      onClick={() => {
                        setShowAutoPayModal(true);
                      }}
                    >
                      Enroll Now
                    </Button>
                  )}
                </div>
              </div>
              <div className="setting">
                <FontAwesomeIcon
                  icon={
                    context.billReminderSettings.enrolled ? faCheck : faTimes
                  }
                  color={
                    context.billReminderSettings.enrolled ? 'green' : undefined
                  }
                />
                <div className="setting-content">
                  <label>
                    {context.billReminderSettings.enrolled
                      ? 'Signed up to receive bill reminders via:'
                      : 'Not receiving bill reminders'}
                  </label>
                </div>
                {context.billReminderSettings.enrolled && (
                  <div className="radio-options">
                    <Checkbox
                      label="Text Message"
                      checked={context.billReminderSettings.textEnabled}
                      onChange={async (e, {checked}) => {
                        await setAccountSetting(
                          'billReminderSettings/textEnabled',
                          checked
                        );
                        if (
                          !checked &&
                          !context.billReminderSettings.emailEnabled
                        ) {
                          await setAccountSetting(
                            'billReminderSettings/enrolled',
                            false
                          );
                        }
                        notifications.success(
                          `Text message bill reminders ${
                            checked ? 'enabled' : 'disabled'
                          }`
                        );
                      }}
                    />

                    <Checkbox
                      label="Email"
                      checked={context.billReminderSettings.emailEnabled}
                      onChange={async (e, {checked}) => {
                        await setAccountSetting(
                          'billReminderSettings/emailEnabled',
                          checked
                        );
                        if (
                          !checked &&
                          !context.billReminderSettings.textEnabled
                        ) {
                          await setAccountSetting(
                            'billReminderSettings/enrolled',
                            false
                          );
                        }
                        notifications.success(
                          `Email bill reminders ${
                            checked ? 'enabled' : 'disabled'
                          }`
                        );
                      }}
                    />
                  </div>
                )}
                <div className="setting-action">
                  {context.billReminderSettings.enrolled && (
                    <Popup
                      on="click"
                      position="left center"
                      trigger={
                        <Button className="clear" type="button">
                          Opt Out
                        </Button>
                      }
                      content={
                        <Button
                          type="button"
                          negative
                          onClick={async () => {
                            await setAccountSetting(
                              'billReminderSettings/enrolled',
                              false
                            );
                            notifications.success(
                              "You've opted out of bill reminders"
                            );
                          }}
                        >
                          Confirm?
                        </Button>
                      }
                    />
                  )}
                  {!context.billReminderSettings.enrolled && (
                    <Button
                      type="button"
                      className="clear"
                      onClick={async () => {
                        await setAccountSetting(
                          'billReminderSettings/enrolled',
                          true
                        );
                        await setAccountSetting(
                          'billReminderSettings/textEnabled',
                          true
                        );
                        await setAccountSetting(
                          'billReminderSettings/emailEnabled',
                          true
                        );
                        notifications.success('Bill reminders enabled');
                      }}
                    >
                      Opt In
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Segment>
          <AsyncStateContainer {...payments}>
            {payments.scheduled.length > 0 && (
              <Segment>
                <Header>Upcoming Payments</Header>
                <Table compact unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Payment method</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right" collapsing>
                        Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" collapsing />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.scheduled.map(x => (
                      <Table.Row key={x.transId}>
                        <Table.Cell>
                          <DateFormat date={x.transDate} />
                        </Table.Cell>
                        <Table.Cell>
                          {`${x.cardUsed} `}
                          <FontAwesomeIcon
                            icon={paymentChannelIcons[x.channel]}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Currency amount={x.amount} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Popup
                            on="click"
                            position="left center"
                            trigger={
                              <Button
                                icon
                                type="button"
                                className="link"
                                style={{padding: '2px 15px'}}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  color="red"
                                  style={{margin: 0}}
                                />
                              </Button>
                            }
                            content={
                              <Button
                                type="button"
                                negative
                                loading={deletePaymentAsync.loading}
                                onClick={async () => {
                                  await deletePaymentAsync.execute(x.key);
                                  notifications.success(
                                    'Scheduled payment cancelled'
                                  );
                                }}
                              >
                                Confirm?
                              </Button>
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            )}
          </AsyncStateContainer>

          <AsyncStateContainer {...payments}>
            {payments.complete.length > 0 && (
              <Segment>
                <Header>Payment History</Header>
                <Table compact unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Date</Table.HeaderCell>
                      <Table.HeaderCell>Payment method</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right" collapsing>
                        Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" collapsing />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {payments.complete.map(x => (
                      <Table.Row key={x.transId}>
                        <Table.Cell>
                          <DateFormat date={x.transDate} />
                        </Table.Cell>
                        <Table.Cell>
                          {`${x.cardUsed} `}
                          <FontAwesomeIcon
                            icon={paymentChannelIcons[x.channel]}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <Currency amount={x.amount} />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Popup
                            content="Re-send receipt"
                            trigger={
                              <Button
                                icon
                                type="button"
                                className="link"
                                style={{padding: '2px 15px'}}
                                onClick={async () => {
                                  await sendEmail(context, x);
                                  notifications.success(
                                    `Receipt sent to ${context.account.emailAddress}`
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faReceipt}
                                  style={{margin: 0}}
                                />
                              </Button>
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            )}
          </AsyncStateContainer>

          <Modal
            open={showAutoPayModal}
            onClose={() => {
              setShowAutoPayModal(false);
            }}
          >
            <Modal.Header>Enroll in Auto Pay</Modal.Header>
            <Modal.Content>
              <AutoPayEnrollment
                onSubmit={() => {
                  setShowAutoPayModal(false);
                  notifications.success("You're now enrolled in auto pay");
                }}
                onCancel={() => setShowAutoPayModal(false)}
              />
            </Modal.Content>
          </Modal>
        </Container>
      )}
    </AsyncStateContainer>
  );
};

const sendEmail = async (context: DemoContext, payment: DemoPayment) => {
  const url =
    'https://demo-paystar-functions.azurewebsites.net/api/SendTemplateEmail?code=Esz6KodvtJkFlvNifTOBkTiItterNKHeVNqswz9wMBMjKMJO4wekaQ==';
  await axios.post(url, {
    ToEmail: context.account.emailAddress,
    TemplateId: 'd-6f7d5b77bafe48c381f1caae589d55f4',
    TemplateData: {
      transactionNumber: payment.transId,
      date: moment(new Date(payment.transDate)).format('MM/DD/YYYY'),
      paidTotal: payment.amount.toFixed(2),
      url: 'https://demo.paystar.co',
      businessUnitName: context.businessUnitName,
      businessUnitImage: context.businessUnitImage,
      feeAmount: context.feeAmount,
      cardType: payment.cardUsed.split(' ')[0],
      cardLast4: payment.cardUsed.split(' ')[1].substr(4, 4),
      accountNumber: context.account.accountNumber,
      organizationName: context.orgName,
    },
  });
};
