import React, {useState} from 'react';
import {Checkbox, Form} from 'semantic-ui-react';
import css from '@emotion/css/macro';

const styles = css`
  .terms-container {
    display: flex;
  }

  .terms-label {
    margin-left: 10px;
  }
`;

type TermsOfUseProps = {
  feeAmount: number;
  processText: string;
  onToggle: (isAccepted: boolean) => void;
};

export const TermsOfUse = (props: TermsOfUseProps) => {
  const [isAccepted, setIsAccepted] = useState(false);

  const {feeAmount, processText, onToggle} = props;

  return (
    <Form.Field css={styles}>
      <label>Terms of Use</label>
      <div className="terms-container">
        <Checkbox
          checked={isAccepted}
          onChange={(e, {checked}) => {
            setIsAccepted(!!checked);
            onToggle(!!checked);
          }}
        />
        <label className="terms-label">
          By checking this box, I agree to the{' '}
          <b>
            <a
              href="https://home.paystar.co/legal"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
          </b>{' '}
          and authorize PayStar to process {processText} along with the stated
          online service fee of ${feeAmount}, which will also be charged to the
          credit/debit card.
        </label>
      </div>
    </Form.Field>
  );
};

TermsOfUse.defaultProps = {
  processText: 'this payment',
};
