import {useState, useEffect} from 'react';

type Props = {
  children: React.ReactChild;
  delayInMs?: number;
};

export const DelayedLoadingContainer = ({
  delayInMs = 500,
  children,
}: Props): any => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShowContent(true);
    }, delayInMs);

    return () => {
      clearTimeout(id);
    };
  }, [delayInMs]);

  return showContent && children;
};
