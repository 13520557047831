import React from 'react';
import {Message} from 'semantic-ui-react';
import {DelayedLoadingContainer} from './delayed-loading-container';

type AsyncStateContainer = {
  loading: boolean;
  error: Error;
  children: React.ReactNode;
};

export const AsyncStateContainer = ({loading, error, children}) => {
  if (loading) {
    return <DelayedLoadingContainer>Loading...</DelayedLoadingContainer>;
  }

  if (error) {
    return <Message negative>{error.message}</Message>;
  }

  return children;
};
