import {useEffect} from 'react';

type MessageHandlerConfig = {
  onMessage: (event: MessageEvent) => void;
  filter?: (event: MessageEvent) => boolean;
};

const DEFAULT_FILTER = () => true;

export const usePostMessageHandler = ({
  filter = DEFAULT_FILTER,
  onMessage,
}: MessageHandlerConfig) => {
  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (filter(event)) {
        onMessage(event);
      }
    };

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [filter, onMessage]);
};
