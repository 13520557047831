import React, {useState} from 'react';
import css from '@emotion/css/macro';
import {
  Button,
  Container,
  Segment,
  Form,
  Radio,
  Icon,
  Divider,
  Popup,
  Modal,
  Message,
  Header,
} from 'semantic-ui-react';
import {Link, RouteComponentProps} from '@reach/router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';
import {Form as FinalForm} from 'react-final-form';
import {take} from 'lodash';
import moment from 'moment';

import {CreditCardSource} from '../../../component-lib/credit-card-source';
import {AsyncStateContainer} from '../../../component-lib/async-state-container';
import Currency from '../../../component-lib/currency';
import {FieldInput} from '../../../forms/fields';
import {useNotification} from '../../../hooks/use-notifications';
import {
  useContext,
  makePayment,
  DemoPaymentBase,
  usePaymentMethod,
  usePayments,
} from '../demo/firebase';
import {TermsOfUse} from '../demo/terms-of-use';
import {SavedCards} from './manage-wallet';
import {ThemeContainer} from '../../../styles/theme-provider';
import {DateFormat} from '../../../component-lib/date';

type BillFormValues = {
  paymentAmount: string;
  paymentDate?: string;
};

const styles = css`
  .ui.form {
    .field > label {
      font-weight: bold !important;
    }
  }

  .field-info-container {
    display: flex;
    flex-direction: column;

    .field-info {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .fee-amount {
    color: #555;
    font-size: 0.9rem;
  }
`;

export const PayMyBill = (props: RouteComponentProps) => {
  const {context, loading, error} = useContext();
  const payments = usePayments();
  const [processNow, setProcessNow] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [selectCard, setSelectCard] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const notifications = useNotification();

  const defaultPaymentMethodId = context
    ? context.defaultPaymentMethodId
    : undefined;

  const paymentMethodState = usePaymentMethod(
    paymentMethodId || defaultPaymentMethodId
  );

  const paymentMethod = paymentMethodState.paymentMethod;

  const asyncState = {loading: loading || paymentMethodState.loading, error};

  return (
    <div css={styles}>
      <AsyncStateContainer {...asyncState}>
        {context && (
          <Container text style={{marginBottom: 20}}>
            <Segment>
              <Header>Pay Your Bill</Header>
              {payments.scheduled.length > 0 && (
                <Message info>
                  {take(payments.scheduled, 1).map((x, i) => (
                    <div key={i}>
                      You have a scheduled payment of{' '}
                      <strong>
                        <Currency amount={x.amount} />
                      </strong>{' '}
                      that will be processed on{' '}
                      <strong>
                        <DateFormat date={x.transDate} />
                      </strong>
                    </div>
                  ))}
                </Message>
              )}
              <FinalForm
                initialValues={{
                  paymentAmount: context.account.balance.toFixed(2),
                }}
                onSubmit={async (values: BillFormValues) => {
                  if (!paymentMethod) {
                    return;
                  }

                  const isFutureDated = !!values.paymentDate;
                  const payment: DemoPaymentBase = {
                    transDate: +(isFutureDated
                      ? moment(values.paymentDate).toDate()
                      : new Date()),
                    isFutureDated,
                    amount: parseFloat(values.paymentAmount),
                    cardUsed: `${paymentMethod.type} ****${paymentMethod.last4}`,
                  };

                  await makePayment(payment, context, paymentMethod);

                  props.navigate('/');

                  notifications.success(
                    values.paymentDate
                      ? 'Payment scheduled successfully'
                      : 'Payment approved. Thank you!'
                  );
                }}
                render={({handleSubmit, submitting, submitErrors, values}) => {
                  let paymentAmount = parseFloat(
                    (values.paymentAmount || '0').toString()
                  );
                  paymentAmount = isNaN(paymentAmount) ? 0 : paymentAmount;

                  let feeAmount = parseFloat(
                    (context.feeAmount || '0').toString()
                  );
                  feeAmount = isNaN(feeAmount) ? 0 : feeAmount;

                  return (
                    <div>
                      {paymentMethod && (
                        <Form
                          onSubmit={handleSubmit}
                          error={!!submitErrors}
                          loading={submitting}
                          size="large"
                        >
                          <Form.Group>
                            <FieldInput
                              label="$"
                              fieldName="paymentAmount"
                              fieldLabel="Payment Amount"
                              width="8"
                            />
                            <Form.Field
                              width="8"
                              className="field-info-container"
                            >
                              <label>&nbsp;</label>
                              <div className="field-info">
                                <label className="fee-amount">
                                  Fee:
                                  <strong>{` ${context.feeAmount} `}</strong>
                                  <Popup
                                    trigger={<Icon name="info circle" />}
                                    content="A separate charge will be reflected on your bank statement."
                                    position="right center"
                                  />
                                </label>
                              </div>
                            </Form.Field>
                          </Form.Group>

                          <Form.Group>
                            <Form.Field width="8">
                              <label>Payment Method</label>
                              <CreditCardSource
                                card={paymentMethod}
                                onSelect={() => {
                                  setSelectCard(true);
                                }}
                              />
                            </Form.Field>
                            <Form.Field
                              width="8"
                              className="field-info-container"
                            >
                              <label>&nbsp;</label>

                              <div className="field-info">
                                <Button
                                  type="button"
                                  className="link"
                                  icon
                                  onClick={() => {
                                    setSelectCard(true);
                                  }}
                                >
                                  Change Payment Method
                                </Button>
                                <Modal
                                  size="small"
                                  open={selectCard}
                                  onClose={() => {
                                    setSelectCard(false);
                                  }}
                                >
                                  <Modal.Header>
                                    Select Payment Method
                                  </Modal.Header>
                                  <Modal.Content>
                                    <Modal.Description>
                                      <ThemeContainer>
                                        <SavedCards
                                          defaultPaymentMethodId={
                                            context.defaultPaymentMethodId
                                          }
                                          onSelect={card => {
                                            setPaymentMethodId(card.id);
                                            setSelectCard(false);
                                          }}
                                        />
                                      </ThemeContainer>
                                    </Modal.Description>
                                  </Modal.Content>
                                </Modal>
                              </div>
                            </Form.Field>
                          </Form.Group>

                          <Form.Field>
                            <label>Schedule Payment</label>
                            <Radio
                              toggle
                              checked={processNow}
                              onChange={(e, {checked}) => {
                                setProcessNow(!!checked);
                              }}
                            />
                          </Form.Field>
                          {processNow && (
                            <Form.Group>
                              <FieldInput
                                fieldName="paymentDate"
                                fieldLabel="Payment Date"
                                width="8"
                                icon="calendar"
                                iconPosition="left"
                                type="date"
                              />
                            </Form.Group>
                          )}

                          <TermsOfUse
                            feeAmount={context.feeAmount}
                            onToggle={accepted => {
                              setTermsAccepted(accepted);
                            }}
                          />

                          <Divider />

                          <div className="form-actions">
                            <Popup
                              content={
                                (values.paymentAmount || 0) <= 0
                                  ? 'Please enter an amount to pay'
                                  : 'Please accept the terms of use to continue'
                              }
                              position="right center"
                              trigger={
                                <div>
                                  {/* div required for popover to work when disabled */}
                                  <Button
                                    primary
                                    icon
                                    size="large"
                                    disabled={
                                      (values.paymentAmount || 0) <= 0 ||
                                      !termsAccepted
                                    }
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                    Pay{' '}
                                    <Currency
                                      amount={
                                        paymentAmount > 0
                                          ? paymentAmount + feeAmount
                                          : 0
                                      }
                                    />
                                  </Button>
                                </div>
                              }
                            ></Popup>
                          </div>
                        </Form>
                      )}
                      {!paymentMethod && (
                        <Message info>
                          <Message.Header>
                            No payment method on file
                          </Message.Header>
                          <Message.Content as={Link} to="/manage-wallet">
                            It doesn't look like you have a payment method on
                            saved. Click here to add one now.
                          </Message.Content>
                        </Message>
                      )}
                    </div>
                  );
                }}
              />
            </Segment>
          </Container>
        )}
      </AsyncStateContainer>
    </div>
  );
};
