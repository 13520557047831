import React from 'react';
import {
  StrictInputProps,
  StrictDropdownProps,
  HtmlInputrops,
} from 'semantic-ui-react';

import {FieldFactory} from './field-factory';
import {InputFieldAdapter, DropdownFieldAdapter} from './field-adapters';

type InputProps = StrictInputProps & HtmlInputrops;

export const FieldInput = FieldFactory<InputProps>({
  renderComponent: props => <InputFieldAdapter {...props} />,
});

export const FieldDropdown = FieldFactory<StrictDropdownProps>({
  renderComponent: props => <DropdownFieldAdapter {...props} />,
});
