import React, {useState} from 'react';
import {hot} from 'react-hot-loader/root';
import {lighten} from 'polished';
import {
  Menu,
  Container,
  Dropdown,
  Message,
  Image,
  Dimmer,
  Button,
  Form,
  Segment,
  Modal,
} from 'semantic-ui-react';
import {Router, Link} from '@reach/router';
import {SkipNavLink, SkipNavContent} from '@reach/skip-nav';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faEnvelopeOpenDollar,
  faSignOut,
  faWallet,
  faUserAlt,
  faUserCog,
} from '@fortawesome/pro-regular-svg-icons';
import {useObjectVal} from 'react-firebase-hooks/database';
import createPersistedState from 'use-persisted-state';
import serialize from 'form-serialize';

import logo from '../../assets/logo-blue.png';
import styled from '../../styles/styled';
import {NavigationLink} from '../../component-lib/navigation-link';
import {AccountCard} from './accounts/account-card';
import {AccountDetails} from './routes/account-details';
import {ManageWallet} from './routes/manage-wallet';
import {PayMyBill} from './routes/pay-bill';
import {DemoContext, database, paths, mergeAccount} from './demo/firebase';
import {DelayedLoadingContainer} from '../../component-lib/delayed-loading-container';
import {ThemeContainer} from '../../styles/theme-provider';
import {useNotification} from '../../hooks/use-notifications';

const AppContainer = styled.div`
  &.log-in-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .log-in {
      width: 400px;
    }
  }

  &.customer-portal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: whitesmoke;
  }

  .header-background {
    position: relative;
    padding: 15px 0 135px;

    .skew {
      background-image: linear-gradient(
        ${props => props.theme.primaryColor},
        ${props => lighten(0.1, props.theme.primaryColor)}
      );
      transform: skewY(-1.5deg);
      transform-origin: top left;
      position: absolute;
      top: 0;
      right: 0;
      bottom: -10px;
      left: 0px;
    }
  }

  .primary-navigation.ui.menu .item {
    &:before {
      width: 0px;
    }

    svg {
      width: 1.18em;
      margin: 0em 10px 0em 0.5em;
      &.icon-right {
        margin: 0em 0.5em 0em 10px;
      }
    }
  }

  .customer-portal-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .ui.menu {
      margin-top: 20px;
    }
  }

  .account-card {
    margin-bottom: 10px !important;
  }

  .customer-portal-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .logo-container {
    padding: 10px 10px 10px 20px;

    .logo {
      width: 75%;
      margin: 10px auto;
    }
  }
`;

const AuthenticatedApp = () => {
  const useAuthenticationState = createPersistedState('authentication');

  const [user, setUser] = useAuthenticationState('');
  const [errors, setErrors] = useState();
  const [editProfile, setEditProfile] = useState(false);
  const [context, loading, error] = useObjectVal<DemoContext>(
    database.ref(paths.context)
  );
  const notifications = useNotification();

  if (loading) {
    return (
      <Container text>
        <DelayedLoadingContainer delayInMs={750}>
          <Dimmer page active />
        </DelayedLoadingContainer>
      </Container>
    );
  }

  if (!context || error) {
    return (
      <Container text>
        <Message negative>Error loading context</Message>
      </Container>
    );
  }

  if (!user) {
    return (
      <AppContainer className="log-in-form-container">
        <div className="log-in">
          <div className="logo-container">
            <Image src={logo} alt="PayStar Logo" className="logo" />
          </div>
          <Segment>
            <Form
              onSubmit={e => {
                const form = serialize(e.target, {hash: true});
                if (form.emailAddress === context.account.emailAddress) {
                  setErrors(false);
                  setUser(context.account);
                } else {
                  setErrors(true);
                }
              }}
            >
              <Form.Field>
                <label>Email Address</label>
                <input type="email" name="emailAddress" />
              </Form.Field>
              <Form.Field>
                <label>Password</label>
                <input type="password" name="password" />
              </Form.Field>
              {errors && <Message negative>Invalid login credentials</Message>}
              <Button primary type="submit">
                Log In
              </Button>
            </Form>
          </Segment>
        </div>
      </AppContainer>
    );
  }

  return (
    <AppContainer className="customer-portal">
      <SkipNavLink />
      <div className="header-background">
        <div className="skew" />
      </div>
      <div className="customer-portal-container">
        <Container text>
          <Menu className="primary-navigation" stackable>
            <Menu.Item>
              <Image src={context.businessUnitImage} size="small" />
            </Menu.Item>
            <NavigationLink to="/">
              <FontAwesomeIcon icon={faAddressCard} />
              My Account
            </NavigationLink>
            <NavigationLink to="/pay-bill">
              <FontAwesomeIcon icon={faEnvelopeOpenDollar} />
              Pay Bill
            </NavigationLink>
            <Menu.Menu position="right">
              <Dropdown
                item
                trigger={
                  <span>
                    <FontAwesomeIcon icon={faUserAlt} />
                    {context.account.name}
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setEditProfile(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faUserCog} className="icon-right" />
                    Manage Profile
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/manage-wallet">
                    <FontAwesomeIcon icon={faWallet} />
                    Manage Wallet
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setUser('');
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOut} className="icon-right" />
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        </Container>
        <div className="customer-portal-content">
          <Container text>
            <AccountCard
              account={{
                number: context.account.accountNumber,
                description: context.account.accountDescription,
                businessUnitName: context.businessUnitName,
                customerName: context.account.name,
                balance: context.account.balance,
                dueDate: new Date(context.account.dueDate),
                pdfLink: context.account.pdfLink,
              }}
            />
          </Container>
          <SkipNavContent />
          <Router>
            <AccountDetails path="/" />
            <PayMyBill path="/pay-bill" />
            <ManageWallet path="/manage-wallet" />
          </Router>
        </div>
      </div>
      <Modal
        open={editProfile}
        onClose={() => {
          setEditProfile(false);
        }}
      >
        <Modal.Header>Update Profile</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ThemeContainer>
              <Form
                onSubmit={async e => {
                  const form = serialize(e.target, {hash: true});
                  await mergeAccount(context.account, form);
                  setEditProfile(false);
                  notifications.success('Profile Updated');
                }}
              >
                <Form.Field>
                  <Form.Field>
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      defaultValue={context.account.name}
                    />
                  </Form.Field>
                  <label>Email Address</label>
                  <input
                    type="email"
                    name="emailAddress"
                    defaultValue={context.account.emailAddress}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    defaultValue={context.account.phoneNumber}
                  />
                </Form.Field>

                <div className="form-actions">
                  <Button primary type="submit">
                    Update Profile
                  </Button>
                  <Button
                    type="button"
                    className="clear"
                    onClick={() => setEditProfile(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </ThemeContainer>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </AppContainer>
  );
};

export default hot(AuthenticatedApp);
