import React, {useState} from 'react';
import {Button, Container, Segment, Header, Modal} from 'semantic-ui-react';
import {RouteComponentProps} from '@reach/router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {useObjectVal} from 'react-firebase-hooks/database';
import cuid from 'cuid';

import {
  CreditCard,
  CreditCardSource,
} from '../../../component-lib/credit-card-source';
import {CardConnectCreditCardForm} from '../../../gateways/card-connect/credit-card-form';
import {
  database,
  paths,
  DemoPaymentMethod,
  DemoContext,
  usePaymentMethods,
  setDefaultPaymentMethod,
} from '../demo/firebase';
import {AsyncStateContainer} from '../../../component-lib/async-state-container';
import {ThemeContainer} from '../../../styles/theme-provider';
import {CardConnectFormValues} from '../../../gateways/card-connect/types';

export const ManageWallet = (props: RouteComponentProps) => {
  const [context, loading, error] = useObjectVal<DemoContext>(
    database.ref(paths.context)
  );

  const [addCardOpen, setAddCardOpen] = useState(false);

  const asyncState = {
    loading,
    error,
  };

  return (
    <AsyncStateContainer {...asyncState}>
      {context && (
        <Container text style={{marginBottom: 20}}>
          <Segment>
            <Header>Saved Payment Methods</Header>
            <SavedCards
              defaultPaymentMethodId={context.defaultPaymentMethodId}
              showEditActions
            />
            <Button
              basic
              color="black"
              fluid
              icon
              onClick={() => {
                setAddCardOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Add New Card
            </Button>
          </Segment>
        </Container>
      )}
      <Modal
        size="small"
        open={addCardOpen}
        onClose={() => {
          setAddCardOpen(false);
        }}
      >
        <Modal.Header>Add new card</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ThemeContainer>
              <CardConnectCreditCardForm
                onSubmit={async (values: CardConnectFormValues) => {
                  const paymentMethod: DemoPaymentMethod = {
                    ...values,
                    id: cuid(),
                    last4: values.token.slice(-4),
                    type: 'Visa',
                  };

                  await database.ref(paths.paymentMethods).push(paymentMethod);

                  await setDefaultPaymentMethod(paymentMethod.id);

                  setAddCardOpen(false);
                }}
                onCancel={() => setAddCardOpen(false)}
              />
            </ThemeContainer>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </AsyncStateContainer>
  );
};

type SavedCards = {
  defaultPaymentMethodId?: string;
  showEditActions: boolean;
  onSelect?: (card: CreditCard) => void;
};

export const SavedCards = (props: SavedCards) => {
  const {defaultPaymentMethodId} = props;

  const paymentMethodsState = usePaymentMethods();

  return (
    <AsyncStateContainer {...paymentMethodsState}>
      {paymentMethodsState.paymentMethods.map(x => (
        <CreditCardSource
          key={x.id}
          card={{
            ...x,
            isDefault: defaultPaymentMethodId === x.id,
          }}
          {...props}
        />
      ))}
    </AsyncStateContainer>
  );
};

SavedCards.defaultProps = {
  showEditActions: false,
};
