import React, {Fragment} from 'react';

const defaultProps = {
  locale: 'en-us',
  currency: 'USD',
};

const Currency = ({amount = 0, locale, currency}) => (
  <Fragment>
    {new Intl.NumberFormat(locale, {style: 'currency', currency}).format(
      amount
    )}
  </Fragment>
);

Currency.defaultProps = defaultProps;

export default Currency;
