import React from 'react';
import {ThemeProvider as EmotionThemeProvider} from 'emotion-theming';
import {Global} from '@emotion/core';
import css from '@emotion/css/macro';
import {darken, lighten, textInputs} from 'polished';
import {Loader} from 'semantic-ui-react';
import {useAsync} from 'react-async-hook';

import styled, {defaultTheme, payStarColors} from './styled';
import {DelayedLoadingContainer} from '../component-lib/delayed-loading-container';

type Theme = typeof defaultTheme;

type Props = {
  children: React.ReactNode;
  resolveTheme: () => Promise<Theme>;
} & Theme;

export const ThemeContainer = styled.div`
  .ui {
    &.primary {
      &.buttons .button,
      &.button {
        background-color: ${props => lighten(0.1, props.theme.primaryColor)};

        .button:hover,
        .button:focus,
        &:hover,
        &:focus {
          background-color: ${props => props.theme.primaryColor};
        }
      }
    }
  }

  a {
    color: ${props => props.theme.primaryColor};

    &.hint {
      color: ${props => lighten(0.1, props.theme.primaryColor)};
    }
  }
`;

const globalStyles = css`
  .root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .ui {
    &.form {
      .ui.labeled.input:not([class*='corner labeled']) .label {
        line-height: 1.3rem;
        border-width: 2px;
        border-color: #cbcfd1;
        padding-top: calc(0.78571429em - 2px);
        padding-bottom: calc(0.78571429em - 2px);

        &:first-of-type + input {
          border-left: 0;
        }
      }

      ${textInputs()} {
        border-width: 2px;
        border-color: ${payStarColors.grey2};

        &:focus {
          outline: -webkit-focus-ring-color auto 3px;
        }
      }

      .field.error {
        ${textInputs()} {
          background: #fff6f6;
          border-color: #e0b4b4;
          color: #9f3a38;
          box-shadow: none;
        }
      }

      .dropdown,
      .selection.dropdown {
        border-width: 2px;
        border-color: ${payStarColors.grey2};

        .menu {
          border-width: 2px;
          border-color: ${payStarColors.grey2};
          margin: 0px -2px;
          min-width: calc(100% + 4px);
          width: calc(100% + 4px);
        }

        &:not(.button) > .default.text,
        &.default:not(.button) > .text {
          color: ${payStarColors.grey1};
        }
      }

      .field > label {
        color: ${payStarColors.textLabel};
        font-weight: normal;

        .hint {
          float: right;
        }
      }

      .form-actions {
        width: 100%;
        display: inline-flex;
        flex-direction: row;

        .secondary-actions {
          flex: 1;
        }
      }
    }

    &.table {
      &.compact th {
        padding: 0.528571em 0.78571429em;
      }

      &.table tr th {
        border-left: none;
      }

      &.celled tr th {
        border-left: 1px solid rgba(222, 222, 222, 0.43);
      }

      thead th {
        border-radius: 0 !important;

        background: ${payStarColors.white2} -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
        background: ${payStarColors.white2} -o-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
        background: ${payStarColors.white2}
          linear-gradient(transparent, rgba(0, 0, 0, 0.05));
        color: ${payStarColors.blue1};
      }

      &.selectable tbody tr {
        cursor: pointer;
      }
    }

    &.button {
      font-weight: normal;

      &.white {
        background: #fff !important;
        box-shadow: 0px 0px 0px 1px rgba(107, 112, 117, 0.35) inset,
          0px 0px 0px 0px rgba(50, 53, 56, 0.15) inset;
      }
      &.clear,
      &.link {
        background: transparent none;
        color: ${payStarColors.primary.blue};
        font-weight: normal;
        border-radius: 0.28571429rem;
        text-transform: none;
        text-shadow: none !important;
        &:hover {
          color: ${darken(0.04, payStarColors.primary.blue)};
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.primary.blue)} !important;
          background-color: ${darken(0.02, payStarColors.white)} !important;
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:active {
          background: #f8f8f8;
        }
      }
      &.link {
        display: inline-block;
        padding: 5px 0px;
      }
      &.basic {
        background-color: ${payStarColors.white} !important;
        color: ${payStarColors.black};

        &:not(.action-button) {
          box-shadow: 0px 0px 0px 1px ${payStarColors.basicBoxShadow} !important;
        }
        &:hover {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${darken(0.02, payStarColors.white)} !important;
        }
        &:focus {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.04, payStarColors.basicBoxShadow)};
          color: ${darken(0.04, payStarColors.black)} !important;
          background-color: ${darken(0.02, payStarColors.white)} !important;
        }
        &:active {
          box-shadow: 0px 0px 0px 1px
            ${darken(0.08, payStarColors.basicBoxShadow)};
          color: ${darken(0.08, payStarColors.black)} !important;
          background-color: ${darken(0.05, payStarColors.white)} !important;
        }

        &.transparent {
          background-color: ${payStarColors.transparent} !important;
          &:hover {
            background-color: ${payStarColors.transparentHover} !important;
          }
          &:active {
            background-color: ${darken(
              0.05,
              payStarColors.transparentHover
            )} !important;
          }
        }

        &.warning {
          background-color: ${payStarColors.warningBackground} !important;
          box-shadow: 0px 0px 0px 1px ${payStarColors.warningBoxShadow} !important;
          color: ${payStarColors.warning};
          &:hover {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, payStarColors.warningBoxShadow)};
            color: ${darken(0.2, payStarColors.warning)} !important;
            background-color: ${darken(
              0.03,
              payStarColors.warningBackground
            )} !important;
          }
          &:focus {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.2, payStarColors.warningBoxShadow)};
            color: ${darken(0.2, payStarColors.warning)} !important;
            background-color: ${darken(
              0.03,
              payStarColors.warningBackground
            )} !important;
          }
          &:active {
            box-shadow: 0px 0px 0px 1px
              ${darken(0.3, payStarColors.warningBoxShadow)};
            color: ${darken(0.3, payStarColors.warning)} !important;
            background-color: ${darken(
              0.05,
              payStarColors.warningBackground
            )} !important;
          }
        }

        &.clear {
          box-shadow: none !important;
        }
      }

      &.icon svg {
        margin-right: 10px;
      }
    }

    &.header {
      &.no-margin {
        margin: 0;
      }
    }

    &.buttons > .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) > .button,
    &.buttons .ui.button:not(.basic):not(.inverted),
    &.buttons:not(.basic):not(.inverted) .button {
      box-shadow: 0px 0px 0px 1px transparent inset,
        0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    }

    &.container {
      @media only screen and (max-width: 767px) {
        width: auto !important;
        margin-left: 0.25em !important;
        margin-right: 0.25em !important;
      }
    }

    &.menu {
      &.pagination {
        border: 1px solid rgba(34, 36, 38, 0.45);
        box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.35);

        .item:before {
          background: rgba(34, 36, 38, 0.3);
        }
      }

      &.secondary.pointing .active.item {
        font-weight: normal;
        color: ${payStarColors.primary.blue};
        border-color: ${payStarColors.primary.blue};
      }
    }

    .modal {
      .content .actions {
        margin: 15px -1.5rem -1.5rem;
        padding: 15px 10px;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.02);
      }

      & > .actions {
        text-align: left;
      }
    }

    .sui-error-message {
      display: block;
      margin: 0.28571429rem 0;
      color: rgb(159, 58, 56);
      font-size: 0.92857143em;
    }

    &.segment {
      padding: 2em 2em;
      display: block;

      &.placeholder {
        min-height: 8rem;
        display: flex;
      }
    }

    a.hint {
      float: right;
      font-weight: normal;
      font-size: 1rem;
    }
  }

  [data-reach-skip-link]:focus {
    z-index: 10;
  }
`;

const DEFAULT_RESOLVE_THEME = (): Promise<Theme> =>
  Promise.resolve(defaultTheme);

export const ThemeProvider = ({children, resolveTheme}: Props) => {
  const fetchTheme = useAsync(resolveTheme, []);

  if (fetchTheme.loading) {
    return (
      <DelayedLoadingContainer delayInMs={500}>
        <Loader inline="centered" active>
          Loading...
        </Loader>
      </DelayedLoadingContainer>
    );
  }

  if (fetchTheme.error || !fetchTheme.result) {
    return <div>Error</div>;
  }

  return (
    <EmotionThemeProvider theme={fetchTheme.result}>
      <Global styles={globalStyles} />
      <ThemeContainer className="root">{children}</ThemeContainer>
    </EmotionThemeProvider>
  );
};

ThemeProvider.defaultProps = {
  ...defaultTheme,
  resolveTheme: DEFAULT_RESOLVE_THEME,
};
